<template>
  <div class="detail">
    <div class="detail-box">
      <div class="title">
        <h3>{{ info.title }}</h3>
      </div>
      <div class="content" v-html="info.content"></div>
      <div
        class="link"
        v-if="
          info.category_id == 3 && info.file_list && info.file_list.length > 0
        "
      >
        附件：<a
          :href="info.file_list[0].url"
          target="_blank"
          rel="noopener noreferrer"
          >{{ info.file_list[0].name }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.getDetail()
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', false)
  },
  methods: {
    getDetail() {
      this.$axios
        .post('/news/detail', {
          id: this.$route.query.id,
        })
        .then(res => {
          this.info = res
        })
    },
  },
}
</script>

<style scoped lang="scss">
%detail {
  .detail {
    margin: 50px auto 0;
    padding: 128px 0 50px;
    background: #f7f7f8;
    min-height: calc(100vh - 718px);
    // width: 1320px;
    &-box {
      max-width: 1320px;
      width: 90%;
      margin: 0 auto;
      .title {
        text-align: center;
        color: #333;
        // margin-bottom: 20px;
        width: 85%;
        margin: 0 auto;
        h3 {
          line-height: 54px;
          font-size: 36px;
          font-weight: bold;
          margin: 0;
          text-align: center;
        }
      }
      .content {
        width: 100%;
        margin: 40px auto 0;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        padding-bottom: 50px;
        ::v-deep p {
          margin: 0;
        }
        ::v-deep img {
          // margin:  auto;
          line-height: 0;
        }
      }
      .link {
        width: 99%;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        a {
          text-decoration: none;
          color: rgb(89, 162, 215);
        }
      }
    }
  }
}
.view-pc {
  @extend %detail;
  @media screen and (max-width: 1200px) {
    .detail {
      width: 100%;
      &-box {
        width: 90%;
        .content {
          width: 100%;
        }
      }
    }
  }
}
// mobile会将px转成vw
.view-mobile {
  @extend %detail;
  .detail {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: calc(100vh - 850px);
    &-box {
      width: 90%;
      margin: 0 auto;
      .title {
        width: 100%;
        h3 {
          line-height: 36px;
          font-size: 36px;
          font-weight: bold;
          margin: 0;
          text-align: justify;
        }
      }
      .content {
        width: 100%;
        margin: 20px auto 0;
        padding-bottom: 30px;
        ::v-deep p {
          font-size: 14px !important;
        }
      }
      .link {
        font-size: 14px;
        line-height: 28px;
        width: 95%;
      }
    }
  }
}
</style>
